<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Consider the reaction shown below:</p>

      <p class="pl-6 mb-4">
        <chemical-latex content="A <=>[k_f][k_r] B" />
      </p>

      <p class="mb-2">
        a) How is the equilibrium constant,
        <stemble-latex content="$\text{K}_\text{eq},$" />
        related to the forward and reverse rate constants?
      </p>

      <s-textarea v-model="inputs.studentAIResponse" outlined />

      <p class="mb-2">
        b) If
        <stemble-latex content="$\text{K}_\text{eq}=$" />
        <number-value :value="Keq" />
        and
        <stemble-latex content="$\text{k}_\text{f}=$" />
        <number-value :value="Kf" unit="\text{s}^{-1}," />
        what is the value of
        <stemble-latex content="$\text{k}_\text{r}?$" />
      </p>

      <calculation-input
        v-model="inputs.studentMathResponse"
        prepend-text="$\text{k}_\text{r}:$"
        append-text="$\text{s}^{-1}$"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question488',
  components: {
    NumberValue,
    STextarea,
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAIResponse: null,
        studentMathResponse: null,
      },
    };
  },
  computed: {
    Keq() {
      return this.taskState.getValueBySymbol('Keq').content;
    },
    Kf() {
      return this.taskState.getValueBySymbol('Kf').content;
    },
  },
};
</script>
